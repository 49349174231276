import VTerminal from "./VTerminal/VTerminal"


const Bio = () => {
  
  return (
    <div className="fill-screen center">
     <VTerminal />
    </div>
  )
}

export default Bio


