class PageNames{
  constructor(){
    this.HOME = "nav1"
    this.EDUCATION = "nav2"
    this.WORK = "nav3"
    this.PROJECTS = "nav4"
    this.MATH = "nav5"
    this.ORIGAMI = "nav6"
    this.CONTACT = "nav7"
  }
}

const PageNames_ = new PageNames()

export default PageNames_