

const details = [
  {
    detail: "Email",
    value: "otissaint74561@gmail.com",
    key: 1
  },
  {
    detail: "Phone",
    value: "07598255955",
    key: 2
  },{
    detail: "Github",
    value: "github.com/Osain002",
    key: 3
  }
]

export {details}